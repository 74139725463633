export const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
export const NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;
export const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH ?? 'localhost';
export const MAIN_DOMAIN = process.env.NEXT_PUBLIC_MAIN_DOMAIN ?? 'http://localhost:4200';
export const INSURANCE_DOMAIN = process.env.NEXT_PUBLIC_INSURANCE_DOMAIN ?? '#';
export const USERS_API_ENDPOINT =
  process.env.NEXT_PUBLIC_USERS_API_ENDPOINT ?? 'http://localhost:8400';
export const MEDIA_API_ENDPOINT =
  process.env.NEXT_PUBLIC_MEDIA_API_ENDPOINT ?? 'http://localhost:8400';
export const REAL_ESTATE_API_ENDPOINT =
  process.env.NEXT_PUBLIC_REAL_ESTATE_API_ENDPOINT ?? 'http://localhost:8400';
export const COMMON_API_ENDPOINT =
  process.env.NEXT_PUBLIC_COMMON_API_ENDPOINT ?? 'http://localhost:8400';
export const CHAT_API_ENDPOINT =
  process.env.NEXT_PUBLIC_CHAT_API_ENDPOINT ?? 'http://localhost:8400';
export const BIHA_API_ENDPOINT =
  process.env.NEXT_PUBLIC_BIHA_API_ENDPOINT ?? 'http://localhost:8400';
export const SOCIAL_API_ENDPOINT =
  process.env.NEXT_PUBLIC_SOCIAL_API_ENDPOINT ?? 'http://localhost:8400';
export const EWALLET_API_ENDPOINT =
  process.env.NEXT_PUBLIC_EWALLET_API_ENDPOINT ?? 'http://localhost:8400';
export const CHAT_SOCKET_API_ENDPOINT =
  process.env.NEXT_PUBLIC_CHAT_SOCKET_API_ENDPOINT ?? 'http://localhost:8401';
export const MAP_API_ENDPOINT = process.env.NEXT_PUBLIC_MAP_API_ENDPOINT ?? 'http://localhost:8402';
export const BLOG_API_ENDPOINT =
  process.env.NEXT_PUBLIC_BLOG_API_ENDPOINT ?? 'http://localhost:8403';
export const NOTARIZATION_API_ENDPOINT =
  process.env.NEXT_PUBLIC_NOTARIZATION_API_ENDPOINT ?? 'http://localhost:8404';
export const E_COMMERCE_API_ENDPOINT =
  process.env.NEXT_PUBLIC_E_COMMERCE_API_ENDPOINT ?? 'http://localhost:8405';
export const ZALO_OA_ID = process.env.NEXT_PUBLIC_ZALO_OA_ID ?? '';
export const FACEBOOK_OA_ID = process.env.NEXT_PUBLIC_FACEBOOK_OA_ID ?? '';
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? '';
export const GOOGLE_CLIENT_SECRET = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRET ?? '';
export const ANALYTICS_ID = process.env.NEXT_PUBLIC_ANALYTICS_ID ?? '';
export const DEFAULT_LOCALE = 'vi';
export const DEFAULT_TITLE = 'ATM NHÀ - Sàn thương mại điện tử cung cấp dịch vụ bất động sản';
export const DEFAULT_SHORT_DESCRIPTION =
  'ATM NHÀ chuyên cung cấp các dịch vụ bất động sản với giá hợp lý như đăng tin bất động sản, định giá bất động sản, tra cứu bản đồ quy hoạch, mạng xã hội bất động sản';
export const DEFAULT_DESCRIPTION = `ATM NHÀ chuyên cung cấp các dịch vụ bất động sản với giá hợp lý như đăng tin bất động sản, định giá bất động sản, tra cứu bản đồ quy hoạch, mạng xã hội bất động sản, tìm kiếm và ký gửi dự án, hưởng hoa hồng từ mạng lưới người dùng. Website: ${MAIN_DOMAIN}`;
export const DEFAULT_OG_IMAGE = '/images/facebook-share-thumbnail.webp';
export const DEFAULT_INIT_STATIC_PAGE = 1000;
export const DEFAULT_CONTACT_PHONE = '0789333322';
export const DEFAULT_SUPPORT_EMAIL = 'contact@atmnha.vn';
export const BANK_NAME = 'TMCP Á CHÂU (ACB)';
export const BANK_CODE = '888127128';
export const BANK_OWNER = 'CONG TY CO PHAN CONG NGHE KY LAN ZMS';
export const BANK_SHORT_NAME = 'ACB';
export const DEFAULT_MAIN_SEARCH_TOOL_PLACEHOLDER =
  'Nhập địa điểm hoặc từ khoá (Ví dụ: Vinhomes hoặc 138 Lý Thường Kiệt hoặc số điện thoại người đ...)';
export const DEFAULT_DISCOVERY_SEARCH_TOOL_PLACEHOLDER = 'Tìm kiếm dịch vụ';
export const DEFAULT_PROJECT_SEARCH_TOOL_PLACEHOLDER = 'Tìm kiếm dự án';
export const NAME_REGEX = /^[A-Za-zÀ-ỹ\s]+$/;
export const MOBILE_DEVICE_REGEX =
  /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i;
export const TAX_REGEX = /^\d{10,15}$/;
export const PHONE_NUMBER_REGEX = /^0([35789])[0-9]{8}$/;
export const VIETNAMESE_ID_NUMBER_REGEX = /^$|\d{9}|\d{12}$/;
export const YOUTUBE_LINK_REGEX = /^(https:\/\/www\.youtube\.com\/watch\?v=[a-zA-Z0-9_-]+[^\s]*)?$/;
export const TAGLINE = 'Sàn thương mại điện tử cung cấp dịch vụ bất động sản';
export const COMPANY_NAME = 'CÔNG TY CỔ PHẦN CÔNG NGHỆ KỲ LÂN ZMS';
export const FOUNDER_NAME = 'Võ Văn Tính';
export const BRANCH_NAME = 'ATM NHÀ';
export const DEFAULT_ALTERNATE_NAME = 'atmnha.vn';
export const DEFAULT_COMPANY_DESCRIPTION =
  'CÔNG TY CỔ PHẦN CÔNG NGHỆ KỲ LÂN ZMS là công ty công nghệ hoạt động về lĩnh vực Thương mại điện tử (TMĐT) Bất động sản. Cung cấp nền tảng trực tuyến để khách hàng có thể tìm kiếm, trao đổi những thông tin về các loại hình nhà, đất thông qua các chức năng đăng tin đối với người bán hoặc Tìm kiếm đối với người mua. Được bắt tay vào nghiên cứu & chính thức chạy thử nghiệm phiên bản đầu tiên vào năm 2018 nhằm đánh giá đúng nhu cầu thị trường, ghi nhận phải hồi từ khách hàng và tối ưu trải nghiệm khách hàng trong quá trình sử dụng với phiên bản ATM NHÀ.';
export const DEFAULT_ADDRESS = '647 Lý Thường Kiệt, phường 11, quân Tân Bình, TPHCM, Việt Nam';
export const DEFAULT_ADDRESS_LOCALITY = 'Thành phố Hồ Chí Minh';
export const DEFAULT_COUNTRY = 'Việt Nam';
export const DEFAULT_EMAIL_ADDRESS = 'contact@atmnha.vn';
export const DEFAULT_ZIP_CODE = '700000';
export const DEFAULT_SOCIAL_MEDIA_LINKS = [
  'https://www.facebook.com/atmnha',
  'https://www.youtube.com/channel/UCawniMq-oiML35r6E7aNAFQ',
  'https://www.tiktok.com/@atmnha',
  'https://www.pinterest.com/atmnhavn/',
  'https://sites.google.com/view/httpsatmnhavn/trang-ch%E1%BB%A7?authuser=1',
  'https://www.tumblr.com/amtnha',
];
export const E_COMMERCE_STORAGE_URL = 'https://hangdoihang.zms.vn';
export const SITE_MAP = {
  REAL_ESTATE: 'bat-dong-san',
  SEARCH_RESULT: {
    INDEX: 'ket-qua-tim-kiem',
    DETAIL: 'chi-tiet-tin-rao',
  },
  SOCIAL_NETWORK: {
    INDEX: 'mang-xa-hoi',
    COMMUNITY_POST: 'cong-dong',
    REAL_ESTATE_POST: {
      INDEX: 'bat-dong-san',
      DETAIL: 'chi-tiet-bat-dong-san',
    },
    SOCIAL_PROFILE: 'trang-ca-nhan',
    NOTIFICATION: 'thong-bao',
    FRIEND: 'ban-be',
  },
  BLOG: {
    INDEX: 'blog',
    LIST_OR_DETAIL_ARTICLE: 'danh-sach-hoac-chi-tiet-bai-dang',
  },
  UPDATE_REAL_ESTATE: 'cap-nhat-tin-rao',
  PROJECT: {
    INDEX: 'du-an',
    DETAIL_PROJECT: {
      INDEX: 'chi-tiet-du-an',
      DETAIL_PRODUCT: 'chi-tiet-san-pham',
    },
  },
  LIST_BROKER: {
    INDEX: 'moi-gioi',
    DETAIL_BROKER: 'chi-tiet-moi-gioi',
  },
  DEPOSIT: {
    INDEX: 'nap-tien',
  },
  AGENT_UPGRADE: {
    INDEX: 'quan-ly-nang-cap',
    HISTORY: 'lich-su',
    PAYMENT: 'thanh-toan',
  },
  PAYMENT: {
    INDEX: 'thanh-toan',
    RESULT: 'ket-qua',
  },
  RESULT_PAYMENT: 'ket-qua-thanh-toan',
  LIST_BOOKING: {
    INDEX: 'quan-ly-booking',
    DETAIL_BOOKING: 'chi-tiet-booking',
    LIST_SEE_HOUSE_BOOKING: {
      INDEX: 'lich-xem-du-an',
      DETAIL_SEE_HOUSE_BOOKING: 'chi-tiet-lich-xem-du-an',
    },
  },
  POSTED_REAL_ESTATE: {
    INDEX: 'quan-ly-tin-rao',
    SAVED_REAL_ESTATE: 'tin-rao-da-luu',
  },
  MY_PROFILE: {
    INDEX: 'thong-tin-cua-toi',
    CHANGE_PASSWORD: 'doi-mat-khau',
    CHANGE_PHONE_NUMBER: 'doi-so-dien-thoai',
  },
  FINANCE: {
    INDEX: 'quan-ly-tai-chinh',
    EWALLET: 'thong-tin-vi',
    HISTORY: 'lich-su',
  },
  MAP: 'ban-do',
  SIGN_UP: 'dang-ky',
  SIGN_IN: 'dang-nhap',
  POST_REAL_ESTATE: 'dang-tin-rao',
  VALUATION: 'dinh-gia',
  DISCOVERY: {
    INDEX: 'kham-pha',
    DETAIL_DISCOVERY: 'chi-tiet-kham-pha',
  },
  RECOVERY: 'khoi-phuc',
  GENERAL_INFORMATION: 'thong-tin-chung',
  INFORMATION_AGENT_UPGRADE: 'thong-tin-nang-cap-dai-ly',
  VIEW_MORE: 'xem-them',
  SERVICE_REQUEST: {
    INDEX: 'quan-ly-yeu-cau-dich-vu',
    NOTARIZATION: {
      INDEX: 'cong-chung',
      DETAIL_NOTARIZATION: 'chi-tiet-cong-chung',
    },
    BANK: {
      INDEX: 'vay-von',
      DETAIL_BANK: 'chi-tiet-vay-von',
    },
    FLUCTUATION: {
      INDEX: 'bien-dong',
      DETAIL_FLUCTUATION: 'chi-tiet-bien-dong',
    },
    FORECLOSURE: {
      INDEX: 'phat-mai',
      DETAIL_FORECLOSURE: 'chi-tiet-phat-mai',
    },
  },
  DEVELOPER_INFORMATION: 'thong-tin-nha-phat-trien',
  MY_NETWORK: {
    INDEX: 'mang-luoi-cua-toi',
    HISTORY: 'lich-su-giao-dich',
    MEMBERS: 'danh-sach-thanh-vien',
  },
  POSTED_PROJECT: {
    INDEX: 'quan-ly-du-an',
    SAVED_PROJECT: 'du-an-da-luu',
    SAVED_PRODUCT: 'san-pham-da-luu',
  },
  MEMBERSHIP_UPGRADE: {
    INDEX: 'nang-cap-hoi-vien',
    UPDATE_MEMBERSHIP: 'cap-nhat-nang-cap',
  },
  NOTARIZATION: {
    INDEX: 'van-phong-cong-chung',
    NOTARIZATION_DOCUMENT: 'ho-so-cong-chung',
  },
  BANK_LOAN: {
    INDEX: 'vay-von-ngan-hang',
    BANK_LOAN_REQUEST: 'yeu-cau-vay-von',
  },
  OFFICE_REGISTRATION: {
    INDEX: 'van-phong-dang-ky',
    FLUCTUATION_REGISTER: 'dang-ky-bien-dong',
  },
  ORDER: {
    INDEX: 'quan-ly-don-hang',
    DETAIL_ORDER: 'chi-tiet-don-hang',
    ADDRESS: 'dia-chi',
  },
  E_COMMERCE: {
    INDEX: 'thuong-mai-dien-tu',
    WISH_LIST: 'san-pham-yeu-thich',
    FLASH_SALE: 'flash-sale',
    BEST_SELLER: 'san-pham-ban-chay',
    PRODUCT: {
      INDEX: 'san-pham',
      LIST_PRODUCT: 'danh-sach-san-pham',
      PREFIX_DETAIL: {
        C: 'c',
        DETAIL_PRODUCT: 'chi-tiet-san-pham',
      },
    },
    DETAIL_SUPPLIER: 'nha-cung-cap',
    CART: 'gio-hang',
    PAYMENT: 'thanh-toan',
    COMPANY: {
      INDEX: 'nha-cung-cap',
      DETAIL_COMPANY: 'chi-tiet-nha-cung-cap',
    },
  },
};
